.heroSection {
  background-image: url("../assets/JMG_background_coming_soon_mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heroCover {
  @apply md:w-[400px];
  @apply bg-black;
}

@media (min-width: 768px) {
  .heroCover {
    opacity: 0.9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .heroSection {
    background-image: url("../assets/JMG_background_coming_soon.jpg");
  }
}
