h3 {
  @apply text-white;
  @apply text-title;
  @apply font-clearface;
}

p {
  @apply text-white;
  @apply text-paragraph;
  @apply font-montserrat;
  @apply font-normal;
}

.menuSection button {
  @apply border-b-0;
  @apply border-t-[1px];
  @apply pt-4;
  @apply pb-2;
}
