@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

@font-face {
  font-family: "ITC_Clearface_Std";
  src: url("assets/fonts/ITC_Clearface_Std/ITC_Clearface_Std.ttf") format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
